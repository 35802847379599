import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {ServiceOrders} from "../models/service-orders/service-orders";
import {ServiceOrder} from "../models/service-orders/service-order";
import {ServiceOrderData} from "../models/service-orders/service-order-data";
import {ApiMessage} from "../models/api-message";


@Injectable({
    providedIn: 'root'
})
export class ServiceOrdersService {

    constructor(protected http: HttpClient) {
    }

    // get all service orders for a logged admin/member/user form API
    public index(userType: string, page?: number, perPage?: number, sort?: string, direction?: string,
                 date_from?: string, date_to?: string, type?: string, status?: string, completed?: string, emergency?: number, search?: string): Observable<ServiceOrders> {

        let url = this.getURLbyUserType(userType);

        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&type=${type}&status=${status}&completed=${completed}&emergency=${emergency}&search=${search}`;
        if (date_from) url += `&date_from=${date_from}&date_to=${date_to}`;

        return this.http.get<ServiceOrders>(url);
    }

    // export selected data from API to *.csv file
    public export(page?: number, perPage?: number, sort?: string, direction?: string,
                  date_from?: string, date_to?: string, type?: string, status?: string, completed?: string, emergency?: number, search?: string): Observable<Blob> {

        let url = `${environment.api}/export`;

        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&type=${type}&status=${status}&completed=${completed}&emergency=${emergency}&search=${search}`;
        if (date_from) url += `&date_from=${date_from}&date_to=${date_to}`;

        return this.http.post(url, {}, {responseType: 'blob'});
    }

    // get one order for a logged admin/member/user form API
    public getServiceOrder(userType: string, id: number): Observable<ServiceOrder> {
        const url = this.getURLbyUserType(userType) + `/${id}`;
        return this.http.get<ServiceOrder>(url);
    }

    // add/create service order in to API
    createServiceOrder(userType: string, data: ServiceOrderData): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.post<ApiMessage>(url, data);
    }

    // update one service order in API only for admin
    update(userType: string, id: number, data: Partial<ServiceOrderData>): Observable<ApiMessage> {
        const url = this.getURLbyUserType(userType);
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // get API URL by a logged admin/member/user
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/admin-service-order`;
                break;
            case 'member':
                url = `${environment.api}/member-service-order`;
                break;
            case 'user':
                url = `${environment.api}/user-service-order`;
                break;
        }
        return url;
    }
}
