<div class="py-4">
    <div *ngIf="isLoading; else content">
        <div class="d-flex justify-content-center py-2">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <ng-template #content>
        <form [formGroup]="depositForm">
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Contract date</mat-label>
                <input matInput [matDatepicker]="contractPicker" [formControl]="date_contract">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="contractPicker"></mat-datepicker-toggle>
                <mat-datepicker #contractPicker (closed)="setContractDate()"></mat-datepicker>
            </mat-form-field>
            <div class="pt-2">
                <mat-form-field class="half-width pt-2" appearance="outline">
                    <mat-label>Contract No:</mat-label>
                    <input matInput formControlName="contract_no" name="contract_no" type="text"
                           placeholder="Contract No"
                           #contractNoLength>
                    <mat-hint align="end">
                        {{ contractNoLength.value.length }} / {{ contractNoMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="contractNo.errors?.['required']">
                        Please enter a contract.
                    </mat-error>
                    <mat-error
                        *ngIf="contractNo.value.length < contractNoMinLength && !contractNo.errors?.['required']">
                        A minimum length of contract no is {{ contractNoMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="contractNo.value.length > contractNoMaxLength">
                        A maximum length of contract no is {{ contractNoMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="pt-2">
                <mat-form-field class="half-width" appearance="outline">
                    <mat-label>Investor:</mat-label>
                    <input matInput formControlName="investor" name="investor" type="text" placeholder="Investor"
                           #investorLength>
                    <mat-hint align="end">
                        {{ investorLength.value.length }} / {{ investorMaxLength }}
                    </mat-hint>
                    <mat-error *ngIf="investor.errors?.['required']">
                        Please enter a investor.
                    </mat-error>
                    <mat-error *ngIf="investor.value.length < investorMinLength && !investor.errors?.['required']">
                        A minimum length of investor is {{ investorMinLength }} characters.
                    </mat-error>
                    <mat-error *ngIf="investor.value.length > investorMaxLength">
                        A maximum length of investor is {{ investorMaxLength }} characters.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="pt-2">
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Return date</mat-label>
                    <input matInput [matDatepicker]="returnPicker" [formControl]="date_return">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="returnPicker"></mat-datepicker-toggle>
                    <mat-datepicker #returnPicker (closed)="setReturnDate()"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="pt-2 mx-md-3" appearance="outline">
                    <mat-label>Amount:</mat-label>
                    <input matInput formControlName="amount" name="amount" type="number" placeholder="0" [min]="0">
                    <mat-icon color="primary" matSuffix>price_change</mat-icon>
                    <mat-error *ngIf="amount.errors?.['required']">
                        Please enter a amount.
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="full-width pt-3" appearance="outline">
                <mat-label>Comment:</mat-label>
                <textarea matInput formControlName="comment" name="comment" type="text"
                          placeholder="Comment" #commentLength></textarea>
                <mat-hint align="end">
                    {{ commentLength.value.length }} / {{ commentMaxLength }}
                </mat-hint>
                <mat-error *ngIf="comment.errors?.['required']">
                    Please enter a comment.
                </mat-error>
                <mat-error *ngIf="comment.value.length < commentMinLength && !comment.errors?.['required']">
                    A minimum length of comment is {{ commentMinLength }} characters.
                </mat-error>
                <mat-error *ngIf="comment.value.length > commentMaxLength">
                    A maximum length of comment is {{ commentMaxLength }} characters.
                </mat-error>
            </mat-form-field>
        </form>
    </ng-template>
</div>
