import {Component} from '@angular/core';

@Component({
    selector: 'app-deposits-current',
    templateUrl: './deposits-current.component.html',
    styleUrl: './deposits-current.component.scss'
})
export class DepositsCurrentComponent {

}
