import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-image-card',
    templateUrl: './image-card.component.html',
    styleUrl: './image-card.component.scss'
})
export class ImageCardComponent {

    @Input() public imageURL = '';
    @Input() public userType = '';
    @Input() public imageID = 0;
    @Input() public imageShow = false;
    @Input() public editMode = false;
    @Input() public isArchive = false;

    @Output() public imageView = new EventEmitter<number>();
    @Output() public imageRemove = new EventEmitter<number>();
    @Output() public imageShowUser = new EventEmitter<{ imageID: number, imageShow: number }>();

    public imageViewEmit(imageID: number): void {
        this.imageView.emit(imageID);
    }

    public imageRemoveEmit(imageID: number): void {
        this.imageRemove.emit(imageID);
    }

    public imageShowUserEmit(imageID: number): void {
        this.imageShow = !this.imageShow;
        const imageShowState = this.imageShow ? 1 : 0;
        this.imageShowUser.emit({imageID: imageID, imageShow: imageShowState});
    }
}
