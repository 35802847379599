import {Component} from '@angular/core';

@Component({
    selector: 'app-deposits-archive',
    templateUrl: './deposits-archive.component.html',
    styleUrl: './deposits-archive.component.scss'
})
export class DepositsArchiveComponent {

}
