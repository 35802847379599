import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {IntercomCodeFormComponent} from "../intercom-code-form/intercom-code-form.component";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {IntercomCodesService} from "../../../shared/services/intercom-codes.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {ApiMessage} from "../../../shared/models/api-message";


@Component({
    selector: 'app-intercom-code-save',
    templateUrl: './intercom-code-save.component.html',
    styleUrl: './intercom-code-save.component.scss'
})
export class IntercomCodeSaveComponent {

    @ViewChild('data') data!: IntercomCodeFormComponent;

    constructor(protected authService: AuthService,
                private intercomCodesService: IntercomCodesService,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    public addIntercomCode(): void {
        this.intercomCodesService.createIntercomCode(this.authService.user.type, this.data.intercomCodeForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/intercom-codes').then();
    }
}
