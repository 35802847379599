import {Component, OnInit, ViewChild} from '@angular/core';
import {DepositFormComponent} from "../deposit-form/deposit-form.component";
import {ActivatedRoute, Router} from "@angular/router";

// services
import {DepositsService} from "../../../shared/services/deposits.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {Deposit} from "../../../shared/models/deposits/deposit";
import {ApiMessage} from "../../../shared/models/api-message";

@Component({
    selector: 'app-deposit-update',
    templateUrl: './deposit-update.component.html',
    styleUrl: './deposit-update.component.scss'
})
export class DepositUpdateComponent implements OnInit {

    @ViewChild('data') data!: DepositFormComponent;

    public isLoading = false;
    public depositID = 0;
    public deposit!: Deposit;

    constructor(private depositsService: DepositsService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.depositID = Number(this.route.snapshot.paramMap.get('id'));
        this.getDepositData(this.depositID);
    }

    public closeForm(): void {
        this.router.navigateByUrl(`secure/deposit-show/${this.depositID}`).then();
    }

    public updateDeposit(): void {
        this.depositsService.updateDeposit(this.depositID, this.data.depositForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    private getDepositData(id: number): void {
        this.isLoading = true;
        this.depositsService.getDeposit(id).subscribe((deposit: Deposit): void => {
            this.deposit = deposit;
            this.isLoading = false;
        })
    }
}
