import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

// service
import {AuthService} from "../../../shared/services/auth.service";
import {DepositsService} from "../../../shared/services/deposits.service";

// models
import {Deposit} from "../../../shared/models/deposits/deposit";
import {Deposits} from "../../../shared/models/deposits/deposits";
import {Router} from "@angular/router";

@Component({
    selector: 'app-deposits-list',
    templateUrl: './deposits-list.component.html',
    styleUrls: ['./deposits-list.component.scss', './../../secure.component.scss']
})
export class DepositsListComponent implements OnInit, AfterViewInit {

    @Input() public isArchive = 'false';

    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['return_date', 'investor', 'contract_no', 'contract_date', 'amount', 'actions'];
    public deposits$ = new MatTableDataSource<Deposit>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public depositsSort = 'return_date';
    public depositsDirection = 'asc';
    public searchFilter = '';

    constructor(protected authService: AuthService,
                private router: Router,
                private depositsService: DepositsService) {
    }

    ngAfterViewInit(): void {
        this.deposits$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        this.loadDeposits();
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadDeposits();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'return_date':
                this.depositsSort = sort.active;
                this.depositsDirection = sort.direction;
                this.loadDeposits();
                return
            case 'investor':
                this.depositsSort = sort.active;
                this.depositsDirection = sort.direction;
                this.loadDeposits();
                return
            case 'contract_no':
                this.depositsSort = sort.active;
                this.depositsDirection = sort.direction;
                this.loadDeposits();
                return
            case 'contract_date':
                this.depositsSort = sort.active;
                this.depositsDirection = sort.direction;
                this.loadDeposits();
                return
            case 'amount':
                this.depositsSort = sort.active;
                this.depositsDirection = sort.direction;
                this.loadDeposits();
                return
            default:
                return
        }
    }

    public addDeposit(): void {
        this.router.navigateByUrl(`secure/deposit-add`).then();
    }

    public showDeposit(id: number): void {
        this.router.navigateByUrl(`secure/deposit-show/${id}`).then();
    }

    public setSearchFilter(): void {
        this.searchFilter.trim().toLowerCase();
        this.loadDeposits();
        this.paginator.firstPage();
    }

    private loadDeposits(): void {
        this.isLoading = true;
        this.depositsService.index(
            this.currentPage,
            this.pageSize,
            this.depositsSort,
            this.depositsDirection,
            this.searchFilter,
            this.isArchive
        ).subscribe((deposits: Deposits): void => {
                this.deposits$ = new MatTableDataSource(deposits.data);
                this.deposits$.sort = this.sort;
                this.paginator.length = deposits.meta.total;
                this.paginator.pageIndex = 0;
                this.paginator.pageIndex = deposits.meta.current_page - 1;
                this.isLoading = false;
            }
        );
    }
}
