<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <app-dashboard-card [imageName]="'service-orders.webp'" [buttonIcon]="'fa-regular fa-rectangle-list'"
                            [buttonName]="'ServiceOrders'" [buttonLink]="'service-orders'"></app-dashboard-card>
        <app-dashboard-card *ngIf="authService.user.type !== 'user'" [imageName]="'intercom-codes.webp'"
                            [buttonIcon]="'fa-solid fa-keyboard'"
                            [buttonName]="'IntercomCodes'" [buttonLink]="'intercom-codes'"></app-dashboard-card>
        <app-dashboard-card *ngIf="authService.user.type !== 'user'" [imageName]="'working-times.webp'"
                            [buttonIcon]="'fa-solid fa-clock-rotate-left'"
                            [buttonName]="'WorkingTimes'" [buttonLink]="'working-times'"></app-dashboard-card>
        <app-dashboard-card *ngIf="authService.user.type === 'admin'" [imageName]="'users.webp'"
                            [buttonIcon]="'fa-solid fa-user-group'"
                            [buttonName]="'UserList'" [buttonLink]="'users'"></app-dashboard-card>
        <app-dashboard-card *ngIf="authService.user.type === 'admin'" [imageName]="'deposit.webp'"
                            [buttonIcon]="'fa-solid fa-money-check-dollar'"
                            [buttonName]="'Deposits'" [buttonLink]="'deposits'"></app-dashboard-card>
    </div>
</div>
