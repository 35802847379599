import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSelectChange} from "@angular/material/select";
import {Router} from "@angular/router";

// services
import {AuthService} from "../../../shared/services/auth.service";
import {ServiceOrdersService} from "../../../shared/services/service-orders.service";
import {ChatMessageService} from "../../../shared/services/chat-message.service";

// models
import {ServiceOrder} from "../../../shared/models/service-orders/service-order";
import {ServiceOrders} from "../../../shared/models/service-orders/service-orders";
import {ServiceOrderMessage} from "../../../shared/models/service-orders/service-order-message";

// date picker
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from "@angular/forms";
import _moment from 'moment';

const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'DD/MM/Y',
        monthYearLabel: 'DD MM Y',
        dateA11yLabel: 'DD MM Y',
        monthYearA11yLabel: 'DD MM Y',
    },
};

@Component({
    selector: 'app-service-orders-list',
    templateUrl: './service-orders-list.component.html',
    styleUrls: ['./service-orders-list.component.scss', './../../secure.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
    ],
})
export class ServiceOrdersListComponent implements OnInit, AfterViewInit {

    @Input() public isArchive = false;

    @ViewChild(MatSort) public sort!: MatSort;
    @ViewChild(MatPaginator) public paginator!: MatPaginator;

    public isLoading = false;
    public displayedColumns: string[] = ['number', 'date', 'type', 'street', 'status', 'show'];
    public orders$ = new MatTableDataSource<ServiceOrder>();
    public pageSizeOptions: number[] = [6, 12, 24, 48];
    public totalRows = 0;
    public pageSize = 6;
    public currentPage = 0;
    public ordersSort = 'id';
    public ordersDirection = 'desc';
    public isMessage = false;
    public emergency = 0;
    public checked = false;

    // show/hide columns
    public numberColumn = false;
    public dateColumn = false;
    public typeColumn = false;
    public addressColumn = true;
    public statusColumn = true;

    // filters
    public date = new FormControl<_moment.Moment | null>(moment());
    public dateFrom: string | undefined = '';
    public dateTo: string | undefined = '';
    public typeFilter = '';
    public statusFilter = '';
    public completed = 'completed';
    public searchFilter = '';

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private chatMessageService: ChatMessageService,
                private router: Router) {
    }

    ngAfterViewInit(): void {
        this.orders$.paginator = this.paginator;
        this.paginator.firstPage();
    }

    ngOnInit(): void {
        this.loadOrders();
        if (this.authService.user.type !== 'user') this.checkMessageGlobal();
    }

    public checkMessage(messages: ServiceOrderMessage[]): boolean {
        let isMessage = false;

        if (this.authService.user.type === 'admin') {
            for (let i = 0; i < messages.length; i++) {
                if (messages[i].admin_read === 0) {
                    if (messages[i].user_type !== this.authService.user.type) {
                        isMessage = true;
                        return isMessage
                    }
                } else isMessage = false;
            }
        }

        if (this.authService.user.type === 'member') {
            for (let i = 0; i < messages.length; i++) {
                if (messages[i].member_read === 0) {
                    if (messages[i].chat_type === 'employee') {
                        isMessage = true;
                        return isMessage
                    }
                } else isMessage = false;
            }
        }

        if (this.authService.user.type === 'user') {
            for (let i = 0; i < messages.length; i++) {
                if (messages[i].user_read === 0) {
                    if (messages[i].chat_type === 'reporter') {
                        isMessage = true;
                        return isMessage
                    }
                } else isMessage = false;
            }
        }
        return isMessage
    }

    public pageChanged(event: PageEvent): void {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex + 1;
        this.loadOrders();
    }

    public showOrder(id: number): void {
        this.router.navigateByUrl(`secure/service-order/${id}`).then();
    }

    public sortData(sort: Sort): void {
        switch (sort.active) {
            case 'number':
                this.ordersSort = 'id';
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'date':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'type':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'street':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            case 'status':
                this.ordersSort = sort.active;
                this.ordersDirection = sort.direction;
                this.loadOrders();
                return
            default:
                return
        }
    }

    public setSearchFromDate(): void {
        this.dateFrom = this.date.getRawValue()?.format('YYYY-MM-DD').toString();
    }

    public setSearchToDate(): void {
        this.dateTo = this.date.getRawValue()?.format('YYYY-MM-DD').toString();
    }

    public searchByDate(): void {
        this.loadOrders();
        this.paginator.firstPage();
    }

    public setTypeFilter(option: MatSelectChange): void {
        this.typeFilter = option.value;
        this.loadOrders();
        this.paginator.firstPage();
    }

    public setStatusFilter(option: MatSelectChange): void {
        this.statusFilter = option.value;
        this.loadOrders();
        this.paginator.firstPage();
    }

    public setSearchFilter(): void {
        this.searchFilter.trim().toLowerCase();
        this.loadOrders();
        this.paginator.firstPage();
    }

    public cleanFilter(): void {
        this.dateFrom = '';
        this.dateTo = '';
        this.typeFilter = '';
        this.statusFilter = '';
        this.searchFilter = '';
        this.date.setValue(moment());
        this.loadOrders();
        this.paginator.firstPage();
    }

    public showEmergency(): void {
        this.checked = !this.checked;
        if (this.checked) {
            this.emergency = 1;
        } else this.emergency = 0;
        this.loadOrders();
        this.paginator.firstPage();
    }

    public exportToCSV(): void {
        this.ordersService.export(
            this.currentPage,
            this.pageSize,
            this.ordersSort,
            this.ordersDirection,
            this.dateFrom,
            this.dateTo,
            this.typeFilter,
            this.statusFilter,
            this.completed,
            this.emergency,
            this.searchFilter
        ).subscribe(
            (res: Blob): void => {
                const blob = new Blob([res], {type: 'text/csv'});
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'service-orders.csv';
                link.click();
            }
        );
    }

    private checkMessageGlobal(): void {
        this.chatMessageService.checkMessage().subscribe((isMessage: boolean): void => {
            this.isMessage = isMessage;
        });
    }

    private loadOrders(): void {
        this.isLoading = true;
        const userType = this.authService.user.type;
        if (this.isArchive) {
            this.statusFilter = 'completed';
            this.completed = '';
        }
        this.ordersService.index(
            userType,
            this.currentPage,
            this.pageSize,
            this.ordersSort,
            this.ordersDirection,
            this.dateFrom,
            this.dateTo,
            this.typeFilter,
            this.statusFilter,
            this.completed,
            this.emergency,
            this.searchFilter
        )
            .subscribe(
                (orders: ServiceOrders): void => {
                    this.orders$ = new MatTableDataSource(orders.data);
                    this.orders$.sort = this.sort;
                    this.paginator.length = orders.meta.total;
                    this.paginator.pageIndex = 0;
                    this.paginator.pageIndex = orders.meta.current_page - 1;
                    this.isLoading = false;
                }
            );
    }
}
