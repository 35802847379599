<form [formGroup]="commentForm">
    <mat-form-field class="full-width mt-2" appearance="outline">
        <mat-label>Comment:</mat-label>
        <textarea matInput formControlName="comment" name="comment" type="text"
                  placeholder="Message" #commentMessage></textarea>
        <mat-hint align="end">
            {{ commentMessage.value.length }} / {{ commentMaxLength }}
        </mat-hint>
        <mat-error *ngIf="comment.errors?.['required']">
            Please enter the comment.
        </mat-error>
        <mat-error *ngIf="comment.value.length < commentMinLength && !comment.errors?.['required']">
            A minimum length of the comment is {{ commentMinLength }} characters.
        </mat-error>
        <mat-error *ngIf="comment.value.length > commentMaxLength">
            A maximum length of the comment is {{ commentMaxLength }} characters.
        </mat-error>
    </mat-form-field>
</form>
