import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-comment-form',
    templateUrl: './comment-form.component.html',
    styleUrl: './comment-form.component.scss'
})
export class CommentFormComponent implements OnInit {

    @Input() public editMode = false;
    @Input() public commentToEdit = '';

    public commentForm!: FormGroup;

    // validators min/max rules
    public commentMinLength = 3;
    public commentMaxLength = 400;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildCommentForm();
        if (this.editMode) {
            setTimeout((): void => {
                this.setComment(this.commentToEdit)
            }, 200);
        }
    }

    public get comment(): AbstractControl {
        return this.commentForm.controls['comment'];
    }

    private buildCommentForm(): void {
        this.commentForm = this.formBuilder.group({
            comment: ['', {
                validators: [
                    Validators.required, Validators.minLength(this.commentMinLength), Validators.maxLength(this.commentMaxLength)]
            }],
        });
    }

    private setComment(comment: string): void {
        this.comment.setValue(comment);
    }
}
