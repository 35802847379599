import {Component, ViewChild} from '@angular/core';
import {DepositFormComponent} from "../deposit-form/deposit-form.component";
import {Router} from "@angular/router";

// services
import {DepositsService} from "../../../shared/services/deposits.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {ApiMessage} from "../../../shared/models/api-message";


@Component({
    selector: 'app-deposit-save',
    templateUrl: './deposit-save.component.html',
    styleUrl: './deposit-save.component.scss'
})
export class DepositSaveComponent {

    @ViewChild('data') data!: DepositFormComponent;

    constructor(private depositsService: DepositsService,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    public addDeposit(): void {
        this.depositsService.createDeposit(this.data.depositForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.closeForm()
        })
    }

    public closeForm(): void {
        this.router.navigateByUrl('/secure/deposits').then();
    }
}
