import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ApiMessage} from "../../../../shared/models/api-message";
import {AuthService} from "../../../../shared/services/auth.service";
import {ServiceOrdersService} from "../../../../shared/services/service-orders.service";
import {SnackbarService} from "../../../../shared/services/snackbar.service";
import {CommentFormComponent} from "../comment-form/comment-form.component";

@Component({
    selector: 'app-service-order-comment',
    templateUrl: './service-order-comment.component.html',
    styleUrl: './service-order-comment.component.scss'
})
export class ServiceOrderCommentComponent {

    @Input() public serviceOrderID = 0;
    @Input() public comment = '';

    @Output() public refreshServiceOrder = new EventEmitter<void>();

    @ViewChild('data') data!: CommentFormComponent;

    public editMode = false;

    constructor(protected authService: AuthService,
                private ordersService: ServiceOrdersService,
                private snackbarService: SnackbarService,) {
    }

    public switchEditComment(): void {
        this.editMode = !this.editMode;
    }

    public updateComment(): void {
        this.ordersService.update(this.authService.user.type, this.serviceOrderID, this.data.commentForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => {
                this.refreshServiceOrder.emit();
                this.switchEditComment();
            }
        })
    }
}
