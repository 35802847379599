import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

// services
import {ImagesService} from "../../shared/services/images.service";
import {SnackbarService} from "../../shared/services/snackbar.service";

// modal components
import {DeleteModalComponent} from "../components/delete-modal/delete-modal.component";
import {ImageViewModalComponent} from "../components/image-view-modal/image-view-modal.component";
import {ServiceOrdersService} from "../../shared/services/service-orders.service";

// models
import {ServiceOrderImage} from "../../shared/models/service-orders/service-order-image";
import {ServiceOrderImageUrl} from "../../shared/models/service-orders/service-order-image-url";
import {AuthService} from "../../shared/services/auth.service";
import {ServiceOrder} from "../../shared/models/service-orders/service-order";
import {ApiMessage} from "../../shared/models/api-message";


@Component({
    selector: 'app-service-order-images',
    templateUrl: './service-order-images.component.html',
    styleUrl: './service-order-images.component.scss'
})
export class ServiceOrderImagesComponent implements OnInit {

    public isLoading = false;
    public serviceOrderID = 0;
    public serviceOrderImages!: ServiceOrderImage[];
    public serviceOrderImagesURL: ServiceOrderImageUrl[] = [];
    public isArchive = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                protected authService: AuthService,
                private imagesService: ImagesService,
                private ordersService: ServiceOrdersService,
                private snackbarService: SnackbarService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.serviceOrderID = Number(this.route.snapshot.paramMap.get('id'));
        this.getServiceOrderImages();
    }

    public back(): void {
        this.router.navigateByUrl(`secure/service-order/${this.serviceOrderID}`).then();
    }

    public addImages(): void {
        this.router.navigateByUrl(`secure/service-order-images-add/${this.serviceOrderID}`).then();
    }

    public imageView(imageID: number): void {
        this.dialog.open(ImageViewModalComponent, {
            maxWidth: '80rem',
            enterAnimationDuration: '2000ms',
            exitAnimationDuration: '1000ms',
            disableClose: false,
            data: {
                serviceOrderID: this.serviceOrderID,
                imageID: imageID,
            }
        })
    }

    public imageRemove(imageID: number): void {
        this.dialog.open(DeleteModalComponent, {
            data: {
                modalService: 'image',
                modalName: 'image',
                id: imageID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.getServiceOrderImages();
                    this.router.navigateByUrl(`secure/service-order-images/${this.serviceOrderID}`).then();
                }
            }
        );
    }

    public changeImageUserShow(image: { imageID: number; imageShow: number }): void {
        const imageUserShowData = {
            image_show: image.imageShow,
        };
        this.imagesService.update(image.imageID, imageUserShowData).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message)
        })
    }

    private getServiceOrderImages(): void {
        this.isLoading = true;
        this.imagesService.getImages(this.authService.user.type, this.serviceOrderID).subscribe((images: ServiceOrderImage[]): void => {
            this.serviceOrderImages = images;
            this.getServiceOrderImagesUrl(images);
            if (this.serviceOrderImages) {
                this.ordersService.getServiceOrder(this.authService.user.type, this.serviceOrderID).subscribe((serviceOrder: ServiceOrder): void => {
                    if (serviceOrder.status === 'completed') this.isArchive = true;
                    this.isLoading = false;
                })
            }
        })
    }

    private getServiceOrderImagesUrl(images: ServiceOrderImage[]): void {
        this.serviceOrderImagesURL = [];
        for (let i = 0; i < images.length; i++) {
            const url = `${environment.apiPublic}/images/service_order/${this.serviceOrderID}/${images[i].image_name}`;
            this.serviceOrderImagesURL.push({imageID: images[i].id, imageURL: url, imageShow: images[i].image_show});
        }
    }
}
