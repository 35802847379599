<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row">
        <div class="col-md-6">
            <mat-card class="bg-white mat-elevation-z3">
                <div class="row">
                    <div class="col-md-6">
                        <mat-card-header>
                            <mat-card-title>
                                <mat-icon class="mx-1" color="primary">price_change</mat-icon>
                                Deposit Add
                            </mat-card-title>
                            <mat-card-subtitle>Add a deposit.</mat-card-subtitle>
                        </mat-card-header>
                    </div>
                </div>
                <mat-card-content>
                    <div class="pt-2">
                        <app-deposit-form #data></app-deposit-form>
                        <app-form-save-button [invalid]="data.depositForm.invalid"
                                              (closeForm)="closeForm()"
                                              (save)="addDeposit()"></app-form-save-button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
