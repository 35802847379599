import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// services
import {DepositsService} from "../../../shared/services/deposits.service";
import {SnackbarService} from "../../../shared/services/snackbar.service";

// models
import {ApiMessage} from "../../../shared/models/api-message";

@Component({
    selector: 'app-deposit-completed-modal',
    templateUrl: './deposit-completed-modal.component.html',
    styleUrl: './deposit-completed-modal.component.scss'
})
export class DepositCompletedModalComponent {

    public depositID = 0;
    public checked = false;

    constructor(private depositsService: DepositsService,
                private snackbarService: SnackbarService,
                private dialogRef: MatDialogRef<DepositCompletedModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                data: {
                    depositID: number
                }) {
        this.depositID = data.depositID;
    }

    public completed(): void {
        const depositData = {
            status: true,
        };
        this.depositsService.updateDeposit(this.depositID, depositData).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.dialogRef.close()
        })
    }
}
