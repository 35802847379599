import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpEvent, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {ApiMessage} from "../models/api-message";
import {ServiceOrderImage} from "../models/service-orders/service-order-image";
import {ImageUserShowData} from "../models/service-orders/image-user-show-data";

class T {
    headers?: HttpHeaders;
    observe?: string;
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ImagesService {

    constructor(private http: HttpClient) {
    }

    // upload service order image(s) to the API only for admin/member
    public uploadImage(userType: string, imageData: FormData): Observable<HttpEvent<T>> {
        const url = this.getURLbyUserType(userType);
        return this.http.post(url, imageData, {
            reportProgress: true,
            observe: 'events',
        })
    }

    // get service order image(s) form API for all users
    public getImages(userType: string, serviceOrderIDID: number): Observable<ServiceOrderImage[]> {
        let url = this.getURLbyUserType(userType);
        url += `?serviceOrderID=${serviceOrderIDID}`;
        return this.http.get<ServiceOrderImage[]>(url);
    }

    // get one service order image form API for all users
    public getImage(id: number): Observable<ServiceOrderImage> {
        return this.http.get<ServiceOrderImage>(`${environment.api}/images/${id}`);
    }

    // update one service order image in API - only admin
    public update(id: number, data: Partial<ImageUserShowData>): Observable<ApiMessage> {
        const url = `${environment.api}/admin-images`;
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }

    // delete one image from API only for admin
    public delete(id: number): Observable<ApiMessage> {
        return this.http.delete<ApiMessage>(`${environment.api}/admin-images/${id}`);
    }

    // get API URL by a logged admin/member
    private getURLbyUserType(userType: string): string {
        let url = '';
        switch (userType) {
            case 'admin':
                url = `${environment.api}/admin-images`;
                break;
            case 'member':
                url = `${environment.api}/member-images`;
                break;
            case 'user':
                url = `${environment.api}/user-images`;
                break;
        }
        return url;
    }
}
