import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// models
import {Deposit} from "../models/deposits/deposit";
import {Deposits} from "../models/deposits/deposits";
import {ApiMessage} from "../models/api-message";


@Injectable({
    providedIn: 'root'
})
export class DepositsService {

    constructor(protected http: HttpClient) {
    }

    // get all deposits form API - only admin
    public index(page?: number, perPage?: number, sort?: string, direction?: string, search?: string, status?: string): Observable<Deposits> {

        let url = `${environment.api}/deposit`;
        if (perPage) url += `?page=${page}&perPage=${perPage}&sort=${sort}&direction=${direction}&search=${search}`;
        if (status) url += `&status=${status}`;

        return this.http.get<Deposits>(url);
    }

    // get one deposit from API - only admin
    public getDeposit(id: number): Observable<Deposit> {
        const url = `${environment.api}/deposit`;
        return this.http.get<Deposit>(`${url}/${id}`);
    }

    // add/create deposit in to API - only admin
    public createDeposit(data: Deposit): Observable<ApiMessage> {
        const url = `${environment.api}/deposit`;
        return this.http.post<ApiMessage>(url, data);
    }

    // update deposit in API - only admin
    public updateDeposit(id: number, data: Partial<Deposit>): Observable<ApiMessage> {
        const url = `${environment.api}/deposit`;
        return this.http.put<ApiMessage>(`${url}/${id}`, data);
    }
}
