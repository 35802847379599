import {Component, Inject, ViewChild} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

// services
import {AuthService} from "../../../../shared/services/auth.service";
import {SnackbarService} from "../../../../shared/services/snackbar.service";
import {ServiceOrdersService} from "../../../../shared/services/service-orders.service";

// models
import {ApiMessage} from "../../../../shared/models/api-message";
import {CommentFormComponent} from "../comment-form/comment-form.component";


@Component({
    selector: 'app-comment-modal',
    templateUrl: './comment-modal.component.html',
    styleUrl: './comment-modal.component.scss'
})
export class CommentModalComponent {

    @ViewChild('data') data!: CommentFormComponent;
    public serviceOrderID = 0;

    constructor(protected authService: AuthService,
                protected formBuilder: FormBuilder,
                private ordersService: ServiceOrdersService,
                private snackbarService: SnackbarService,
                private dialogRef: MatDialogRef<CommentModalComponent>,
                @Inject(MAT_DIALOG_DATA)
                data: {
                    serviceOrderID: number
                }) {
        this.serviceOrderID = data.serviceOrderID;
    }

    public addComment(): void {
        this.ordersService.update(this.authService.user.type, this.serviceOrderID, this.data.commentForm.getRawValue()).subscribe({
            next: (res: ApiMessage): void => {
                this.snackbarService.snackbarCustomComplete(res.message);
            },
            error: (e) => this.snackbarService.snackbarCustomError(e.error.message),
            complete: () => this.dialogRef.close()
        })
    }
}
