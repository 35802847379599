import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

// services
import {DepositsService} from "../../../shared/services/deposits.service";
import {Deposit} from "../../../shared/models/deposits/deposit";

// modals
import {DepositCompletedModalComponent} from "../deposit-completed-modal/deposit-completed-modal.component";


@Component({
    selector: 'app-deposit-show',
    templateUrl: './deposit-show.component.html',
    styleUrl: './deposit-show.component.scss'
})
export class DepositShowComponent implements OnInit {

    public isLoading = false;
    public depositID = 0;
    public deposit!: Deposit;

    constructor(private depositsService: DepositsService,
                private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.depositID = Number(this.route.snapshot.paramMap.get('id'));
        this.getDepositData(this.depositID);
    }

    public editDeposit(): void {
        this.router.navigateByUrl(`secure/deposit-update/${this.depositID}`).then();
    }

    public completed(): void {
        this.dialog.open(DepositCompletedModalComponent, {
            data: {
                depositID: this.depositID
            }
        }).afterClosed().subscribe(
            (res): void => {
                if (!res) {
                    this.router.navigateByUrl(`/secure/deposits`).then();
                }
            }
        );
    }

    public back(): void {
        if (this.deposit.status) {
            this.router.navigateByUrl(`/secure/deposits-archive`).then();
        } else this.router.navigateByUrl(`/secure/deposits`).then();
    }

    private getDepositData(id: number): void {
        this.isLoading = true;
        this.depositsService.getDeposit(id).subscribe((deposit: Deposit): void => {
            this.deposit = deposit;
            this.isLoading = false;
        })
    }
}
