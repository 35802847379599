import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Deposit} from "../../../shared/models/deposits/deposit";

// date picker
import {FormControl} from "@angular/forms";
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import _moment from 'moment';

const moment = _moment;

export const DATE_FORMAT = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'DD/MM/Y',
        monthYearLabel: 'DD MM Y',
        dateA11yLabel: 'DD MM Y',
        monthYearA11yLabel: 'DD MM Y',
    },
};

@Component({
    selector: 'app-deposit-form',
    templateUrl: './deposit-form.component.html',
    styleUrl: './deposit-form.component.scss',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT},
    ],
})
export class DepositFormComponent implements OnInit {

    @Input() public editMode = false;
    @Input() public deposit!: Deposit;

    public date_contract = new FormControl<_moment.Moment | null>(moment());
    public date_return = new FormControl<_moment.Moment | null>(moment());
    public isLoading = false;
    public depositForm!: FormGroup;
    public investorMinLength = 3;
    public investorMaxLength = 100;
    public contractNoMinLength = 3;
    public contractNoMaxLength = 100;
    public commentMinLength = 3;
    public commentMaxLength = 400;

    constructor(protected formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.buildDepositForm();
        this.setCurrentDateTime();
        if (this.editMode) {
            this.isLoading = true;
            setTimeout((): void => {
                this.setDeposit(this.deposit);
                this.isLoading = false;
            }, 500);
        }
    }

    public get returnDate(): AbstractControl {
        return this.depositForm.controls['return_date'];
    }

    public get investor(): AbstractControl {
        return this.depositForm.controls['investor'];
    }

    public get contractNo(): AbstractControl {
        return this.depositForm.controls['contract_no'];
    }

    public get contractDate(): AbstractControl {
        return this.depositForm.controls['contract_date'];
    }

    public get amount(): AbstractControl {
        return this.depositForm.controls['amount'];
    }

    public get comment(): AbstractControl {
        return this.depositForm.controls['comment'];
    }

    public setCurrentDateTime(): void {
        this.date_contract.setValue(moment());
        this.date_return.setValue(moment());
        this.contractDate.setValue(moment()?.format('YYYY-MM-DD'));
        this.returnDate.setValue(moment()?.format('YYYY-MM-DD'));
    }

    public setContractDate(): void {
        this.contractDate.setValue(this.date_contract.getRawValue()?.format('YYYY-MM-DD'));
    }

    public setReturnDate(): void {
        this.returnDate.setValue(this.date_return.getRawValue()?.format('YYYY-MM-DD'));
    }

    private buildDepositForm(): void {
        this.depositForm = this.formBuilder.group({
            contract_date: ['', {validators: [Validators.required]}],
            contract_no: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.contractNoMinLength), Validators.maxLength(this.contractNoMaxLength)]
            }],
            investor: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.investorMinLength), Validators.maxLength(this.investorMaxLength)]
            }],
            amount: ['', {validators: [Validators.required]}],
            return_date: ['', {validators: [Validators.required]}],
            comment: ['', {
                validators: [Validators.minLength(this.commentMinLength), Validators.maxLength(this.commentMaxLength)]
            }],
        });
    }

    private setDeposit(deposit: Deposit): void {
        const {...formData} = deposit;
        this.depositForm.patchValue(formData);
        this.date_contract.setValue(moment(deposit.contract_date));
        this.date_return.setValue(moment(deposit.return_date));
        if (deposit.comment) {
            this.comment.setValue(deposit.comment);
        } else this.comment.setValue('');
    }
}
